<template>
  <div>
    <div class="ygdtitle">新订单</div>
    <div class="orderdetail">
      <div class="num">
        订单编号{{ num }}<span @click="back()">返回列表</span>
      </div>
      <steps :active="active"></steps>
      <trace :active="traceactive" :trace="trace"></trace>
      <articlecont :article="article"></articlecont>
      <!-- <div class="uopoadwj">
        <el-upload
          class="upload-demo"
          ref="upload"
          :action="doUpload"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :on-change="beforeUpload"
          :on-success="upsuccess"
          :data="{ id: wid, state: active }"
          :file-list="fileList"
          :auto-upload="false"
        >
          <el-button slot="trigger" size="small" type="primary"
            >选取文件</el-button
          >
          <el-button
            style="margin-left: 10px"
            size="small"
            type="danger"
            @click="submitUpload"
            >点击上传</el-button
          >
          <div slot="tip" class="el-upload__tip"></div>
        </el-upload>
      </div> -->
      <div class="contact">
        <div class="contact_left">
          <span><i class="el-icon-phone"></i>{{ article.phone }}</span>
          <span style="cursor: pointer; margin-left: 10px" class="kf"
            ><i class="el-icon-chat-dot-round"></i>在线沟通</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import steps from '../components/steps'
import trace from '../components/trace'
import articlecont from '../components/articlecont'
import { ordernum, ordertrace, orderarti } from '../../../API/YGD/orderdetail.js'
export default {
  components: {
    steps,
    trace,
    articlecont
  },
  data () {
    return {
      doUpload: '',
      fileName: "", // 获取文件文件名
      wid: "",//当前作品ID
      active: 0, //订单活跃状态
      trace: [],
      article: {},
      num: "",
      fileList: [],//已经上传的文件列表

      traceactive: 1,
    }
  },
  mounted () {
    this.wid = this.$route.query.wid;
    this.ordersnum(this.wid);
    this.orderstrace(this.wid)
    this.ordersarti(this.wid)
    this.upLoading()
  },
  methods: {

    upLoading () {
      this.doUpload = this.URL + '/staff/UploadAttachment'
      console.log(this.doUpload)
    },
    //查看当前订单状态
    ordersnum (id) {
      ordernum(id).then(res => {
        this.num = res.data.data.contributionnumber
        this.active = res.data.data.state
        if (res.data.data.state == 0 || res.data.data.state == 1 || res.data.data.state == 71 || res.data.data.state == 72 || res.data.data.state == 81 || res.data.data.state == 82 || res.data.data.state == 54 || res.data.data.state == 55) {
          this.active = 2
        } else if (res.data.data.state == 2 || res.data.data.state == 21 || res.data.data.state == 22) {
          this.active = 3
        } else if (res.data.data.state == 30 || res.data.data.state == 31 || res.data.data.state == 32 || res.data.data.state == 5 || res.data.data.state == 6) {
          this.active = 4
        } else {
          this.active = 5
        }

        console.log(this.active)
      })
    },
    //查看追踪
    orderstrace (id) {
      ordertrace(id).then(res => {
        this.trace = res.data.data
        this.traceactive = this.trace.length - 1
      })
    },
    //查看文章详情
    ordersarti (id) {
      orderarti(id).then(res => {

        this.article = res.data.data
      })
    },
    //返回列表
    back () {
      this.$router.push({ path: '/Staff/TodoOrder', query: { id: 0 } })
    },
    //文件上传
    submitUpload () {
      var that = this
      if (that.fileName == '') {
        that.$message.error('未选择文件')
      } else {
        that.$refs.upload.submit()
        setTimeout(function () {
          that.$router.push({ path: '/Staff/TodoOrder', query: { id: 0 } })
        }, 3000)
      }
    },
    handleRemove (file, fileList) {
      console.log(file, fileList);
    },
    handlePreview (file) {
      console.log(file.name);
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    upsuccess (res, file, filelist) {
      if (res.code == 0) {
        this.$message.success("上传成功")
      } else {
        this.$message.error("上传失败")
      }
    },
    beforeUpload (file, fileList) {
      this.fileName = file.name
      console.log(file, fileList)
    }
  }
}
</script>

<style>
.ygdtitle {
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
}
.kf:hover {
  color: #ff1918;
}
.contact {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  height: 80px;
  justify-content: space-between;
  align-items: center;
}
</style>